/**
 * Created by laszlo.czondor on 2017. 09. 20.
 *
 * Given hour or minute values (values between 0 and 60 is assumed), determine whether leading zeroes are needed.
 * If not, then just convert numeric value to string.
 */

function setLeadingZero(number) {
	let result = '';

	if (number < 10) {
		result = '0' + number;
	} else {
		result = '' + number;
	}

	return result;
}

export default setLeadingZero;
