import rcoApi from '../../../api/rco-api';
import { mapState } from 'vuex';
import VueFormNotification from '@ui-common/vue-form-notification';

export default {
	name: 'RCOAccountPackageSelectorButton',
	props: {
		hasZeroPage: {
			type: Boolean,
			required: true
		},
		packageId: {
			type: String,
			required: true
		},
		timeReservationUrl: {
			type: String,
			required: false
		}
	},
	components: {
		VueFormNotification
	},
	computed: {
		...mapState('rcoAccountPackagePicker', ['packages', 'technicalError'])
	},
	methods: {
		preparePackageData() {
			const pack = Object.assign({}, this.packages[this.packageId]);
			delete pack.availableCards;
			delete pack.displayColumnId;
			pack.packageId = this.packageId;
			pack.isMobileBanker = false;
			return pack;
		},
		gotoTimeReservation() {
			if (this.timeReservationUrl) {
				window.location.href = `${this.timeReservationUrl}?packageId=${this.packageId}`;
			}
		},
		async gotoPackage() {
			const pack = this.preparePackageData();
			this.$store.dispatch('rcoAccountPackagePicker/setTechnicalError', false);
			try {
				const response = await rcoApi.sendPackageData(pack);
				let url = response.data.url;
				if (this.hasZeroPage) {
					url += '?has-zero-page=true';
				}
				window.location.href = url;
			} catch (err) {
				this.$store.dispatch('rcoAccountPackagePicker/setTechnicalError', true);
			}
		}
	}
};
