
import { defineComponent } from 'vue';
export default defineComponent({
	props: {
		url: {
			type: String,
			required: true
		}
	},
	setup(props) {
		return {
			src: props.url + window.location.search
		};
	}
});
