/**
 * Created by laszlo.czondor on 2017. 09. 20.
 *
 * Determine which hungarian postfix is needed for 'from [hour]:[minute]'.
 */

function getMinutesPostfix(minutes) {
	let result = '';

	switch (minutes) {
		case '00':
		case '30':
			result = '-tól';
			break;
		case '15':
		case '45':
			result = '-től';
			break;
		default:
			break;
	}

	return result;
}

export default getMinutesPostfix;
