export default {
	data() {
		return {
			receivesMoney: false
		};
	},
	methods: {
		changeReceivesMoney(refId) {
			this.receivesMoney = !this.receivesMoney;
			this.$nextTick(() => {
				this.$refs[refId].focus();
			});
		}
	}
};
