/**
 * beszélget az időpontfoglalóval
 * @author sarkiroka on 2017.09.19.
 */
import axios from 'axios';

export default {
	getTheme(themeId) {
		return axios.get(`/apps/bookappointment/api/theme/${themeId}`);
	},
	init() {
		return axios.get('/apps/bookappointment/api/appointment/init');
	},
	getAppointment(token) {
		return axios.get(`/apps/bookappointment/api/appointment/${token}`);
	},
	getAppointmentList(themeId, branchCode) {
		let data = { branchCode, themeId };
		return axios.post('/apps/bookappointment/api/appointment/list', data);
	},
	postReservationForm(data) {
		return axios.post('/apps/bookappointment/api/personalinformation', data);
	},
	sendTa(data) {
		return axios.post('/apps/bookappointment/api/ta', data);
	}
};
