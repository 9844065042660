
import Lottie from 'vue-lottie/src/lottie.vue';
import * as animationData from '../../../assets/img/anim-json/lottie.json';

export default {
	components: {
		lottie: Lottie
	},
	data() {
		return {
			defaultOptions: { animationData: animationData },
			animationSpeed: 1
		};
	}
};
