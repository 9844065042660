/**
 * Created by laszlo.czondor on 2017. 09. 20.
 *
 * Determine which hungarian postfix is needed for 'on the nth [day] of [month]'.
 */

const DAY_POSTFIX_AN = [2, 3, 6, 8, 13, 16, 18, 20, 23, 26, 28, 30];

function getDayPostfix(day) {
	let result = '';

	if (DAY_POSTFIX_AN.indexOf(day) !== -1) {
		result = '-án';
	} else {
		result = '-én';
	}

	return result;
}

export default getDayPostfix;
