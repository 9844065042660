/**
 * Created by laszlo.czondor on 2017. 09. 24.
 *
 * This module is the end result of a time reservation process.
 * There are three states: success, error, timeout.
 */

import getQueryParam from 'get-query-param';
import bookappointmentApi from '../../api/bookappointment-api';

import getDayPostfix from './get-day-postfix';
import getMinutesPostfix from './get-minutes-postfix';
import setLeadingZero from './set-leading-zero';
import moment from 'moment';

const ERROR_TOKEN = 'error';
const TIMEOUT_TOKEN = 'timeout';
const SUCCESS_TOKEN = 'success';
const GTM_DATE_FORMAT = 'YYYY.MM.DD., HH:mm';
const WEEKDAY_NAMES_INFLECTED = ['vasárnapon', 'hétfőn', 'kedden', 'szerdán', 'csütörtökön', 'pénteken', 'szombaton'];
const MONTH_NAMES = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];

const SUCCESS_TA = 'JOVAHAGYO_EMAIL_MEGEROSITES_SIKERES';
const TIMEOUT_TA = 'JOVAHAGYO_EMAIL_MEGEROSITES_SIKERTELEN_IDOPONTTULEPES';
const FAIL_TA = 'JOVAHAGYO_EMAIL_MEGEROSITES_SIKERTELEN_OTP';
const DELETE_SUCCESS_TA = 'JOVAHAGYO_EMAIL_TORLES_SIKERES';
const DELETE_FAIL_TA = 'JOVAHAGYO_EMAIL_TORLES_SIKERTELEN';
const TERMEK_URL_TA = 'TERMEK_URL';
const GTM_SUCCESS = 'mapBanklistReserveSucess';
const GTM_FAIL = 'mapBanklistReserveSlotFailed';
const GTM_FAIL_TIMEOUT = 'mapBanklistFailedTimeOut';
const GTM_DELETE = 'mapBanklistSelectDisclaim';

export default {
	data() {
		return {
			state: '',
			message: {},
			param: '',
			productUrl: ''
		};
	},
	props: {
		delete: {
			type: Boolean,
			default: true
		},
		timeout: {
			type: Boolean,
			default: false
		},
		error: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		date() {
			let result = '';

			if (this.message && this.message.startDate) {
				let date = new Date(this.message.startDate);

				let month = date.getMonth();
				let day = date.getDate();
				let weekday = date.getDay();

				let hours = setLeadingZero(date.getHours());
				let minutes = setLeadingZero(date.getMinutes());

				let monthName = MONTH_NAMES[month];
				let dayPostfix = getDayPostfix(day);
				let dayNameInflected = WEEKDAY_NAMES_INFLECTED[weekday];
				let minutesPostfix = getMinutesPostfix(minutes);

				result = `${monthName} ${day}${dayPostfix} (${dayNameInflected}) ${hours}:${minutes}${minutesPostfix}`;
			}

			return result;
		}
	},
	methods: {
		scrollDown() {
			setTimeout(() => {
				let heading = document.querySelector('.mife-title');
				heading.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'nearest'
				});
			}, 1000);
		},
		sendTa(data) {
			bookappointmentApi.sendTa(data);
		}
	},
	mounted() {
		let token = getQueryParam('token', window.location.href);
		let param = getQueryParam('param', window.location.href);
		if (token) {
			this.param = token;
		}
		if (param === ERROR_TOKEN || param === TIMEOUT_TOKEN || param === '') {
			this.state = param;
			this.scrollDown();
		} else {
			bookappointmentApi
				.getAppointment(token)
				.then((response) => {
					this.productUrl = (response.data && response.data.productUrl) || '';
					let taData;
					let taProductId = { name: TERMEK_URL_TA, value: this.productUrl };
					this.message = response.data;
					this.state = SUCCESS_TOKEN;
					if (this.timeout) {
						taData = {
							eventType: TIMEOUT_TA,
							dataElements: []
						};
						this.$gtm.trackEvent({
							event: GTM_FAIL_TIMEOUT
						});
					} else if (this.delete) {
						taData = {
							eventType: DELETE_SUCCESS_TA,
							dataElements: []
						};
						this.$gtm.trackEvent({
							event: GTM_DELETE,
							interaction: 'Sikeres időpont törlés!',
							mapBanklistSlotTime: moment(response.data.startDate).format(GTM_DATE_FORMAT),
							at: response.data.bookappointmentId
						});
					} else if (this.error) {
						taData = {
							eventType: FAIL_TA,
							dataElements: []
						};
						this.$gtm.trackEvent({
							event: GTM_FAIL
						});
					} else {
						taData = {
							eventType: SUCCESS_TA,
							dataElements: []
						};
						this.$gtm.trackEvent({
							event: GTM_SUCCESS,
							interaction: 'Sikeres időpontfoglalás!',
							mapBanklistSlotTime: moment(response.data.startDate).format(GTM_DATE_FORMAT),
							at: response.data.bookappointmentId
						});
					}
					taData.dataElements.push(taProductId);
					bookappointmentApi.sendTa(taData);
					this.scrollDown();
				})
				.catch((error) => {
					this.productUrl = (error.response && error.response.data && error.response.data.productUrl) || '';
					let taProductId = { name: TERMEK_URL_TA, value: this.productUrl };
					let taData;
					if (this.timeout) {
						this.$gtm.trackEvent({
							event: GTM_FAIL_TIMEOUT
						});
					} else if (this.delete) {
						taData = {
							eventType: DELETE_FAIL_TA,
							dataElements: []
						};
					} else {
						taData = {
							eventType: FAIL_TA,
							dataElements: []
						};
						this.$gtm.trackEvent({
							event: GTM_FAIL
						});
					}
					if (taData && taData.dataElements) {
						taData.dataElements.push(taProductId);
					}
					bookappointmentApi.sendTa(taData);
					this.state = ERROR_TOKEN;
					this.scrollDown();
				});
		}
	},
	watch: {
		state: {
			handler(val) {
				if (val == 'succes') {
					this.$gtm.trackEvent({
						event: 'appointmentReservation',
						interaction: 'Sikeres időpontfoglalás!'
					});
				} else if (val == 'timeout') {
					this.$gtm.trackEvent({
						event: 'appointmentReservation',
						interaction: 'Sikertelen időpontfoglalás'
					});
				} else if (val === 'error' || val === '') {
					this.$gtm.trackEvent({
						event: 'appointmentReservation',
						interaction: 'Sikertelen időpontfoglalás - megerősítés hiányzik'
					});
				}
			},
			immediate: true
		}
	}
};
